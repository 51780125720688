<div style="float: left; width: 70%;">
  <h1>About Me</h1>
  <p style="margin-bottom: 0px;">
    Welcome to my website!<br><br>
  </p>
</div>

<div style="float: right; width: 30%;">
  <img src="assets/Profilbild.jpg" alt="Profile picture">
</div>

  <p>
    My name is <b>Insa Belter</b> and I currently study <b>Medical Data Science</b> at <a target="_blank" href="https://www.hs-mannheim.de/" style="text-decoration: none; font-weight: bold;">Hochschule Mannheim</a>.<br>
    Meanwhile, I work part-time at <a target="_blank" href="https://eviden.com/" style="text-decoration: none; font-weight: bold;">Eviden Germany GmbH</a> in Frankfurt am Main.<br>
    In 2022 I have finished my bachelor's degree in <b>Applied Computer Science</b> at <a target="_blank" href="https://www.mannheim.dhbw.de" style="text-decoration: none; font-weight: bold;">DHBW Mannheim</a> with the grade 1.2.<br><br>
    On this website you can find some information of side-projects of mine as well as some games to play.<br>Just click around :)<br><br>
    If you want to <b>contact me</b>, you can write a message below:
  </p>
  <form #form [formGroup]="messageForm" class="example-form">

    <mat-form-field class="example-full-width">
      <mat-label>Name</mat-label>
      <input [formControlName]="'name'" matInput>
      <mat-error>required</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Mail Address</mat-label>
      <input [formControlName]="'email'" matInput placeholder="example@text.com">
      <mat-error>required</mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Message</mat-label>
      <textarea [formControlName]="'message'" matInput id="message" placeholder="Write your message here ..."></textarea>
      <mat-error>required</mat-error>
    </mat-form-field>

    <!-- reCAPTCHA -->
    <div #recaptcha style="margin-top: 5px;"></div>

    <p style="margin-top: 10px;">{{notification}}</p>
    <button mat-raised-button style="margin-bottom: 10px;" color="primary" type="button" (click)="onSendMessage()">Send Message</button>
  </form>



