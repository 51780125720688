import { SimpleChanges, ViewChild } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit, OnChanges } from '@angular/core';
import { DicePointsWindowComponent } from './dice-points-window/dice-points-window.component';

@Component({
  selector: 'app-games-page',
  templateUrl: './games-page.component.html',
  styleUrls: ['./games-page.component.scss']
})
export class GamesPageComponent implements OnChanges{

  panelOpenState = false;

  @Output() pointAccount: Record<number, number> = {};

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.pointAccount);
  }

}
