<mat-card>
  <mat-card-title *ngIf="extensionTitle == false">Round {{round}}</mat-card-title>
  <mat-card-title *ngIf="extensionTitle == true">Round {{round}} - Extension</mat-card-title>

  <h2 *ngIf="phase!=1">Try {{try}}</h2>
  <h2 *ngIf="phase==1">Try {{try-1}} → {{try}}</h2>

  <p *ngIf="phase==0 || phase==1">Please roll the dices!</p>
  <p *ngIf="phase==2 && points<350">Please choose the results to keep!</p>
  <p *ngIf="phase==2 && points>=350">You have <b>{{points}}</b> points and can end your round!<br>If you feel lucky, you can instead continue your round and choose the results to keep.</p>
  <p *ngIf="phase==3 && points<350">You did not gain points this round. :(<br>Please end your round!</p>
  <p *ngIf="phase==3 && points>=350">You gained <b>{{points}}</b> points this round! :)<br>Please end your round!</p>
  <p *ngIf="phase==4">Yeah! All your results gain points, so let's play an extension!</p>

  <div *ngIf="(phase == 0 || phase == 1)">
    <button mat-icon-button
      (click)="onRollTheDices()"
      style="margin-right: 30px; margin-bottom: 20px; height: 80px; width: 80px;">
    <img src="assets/dice-game/dice.png" alt="dice" style="height: 80px;"></button>
  </div>

  <!-- After dices rolled -->
  <div *ngIf="phase != 0">
    <div style="margin-bottom: 20px;">
      <button mat-raised-button
        *ngFor="let number of diceResults; index as i"
        class="inline-element" id="button{{i}}"
        [disabled]="buttonStates[i]"
        [color]="(newButtonsChosen.has(i) && phase == 2) ? 'primary' : ''"
        [ngClass]="{'disable-click': phase != 2, 'chosen-button': ((buttonsChosen.has(i) && (!newButtonsChosen.has(i))) || (phase==3 && !buttonStates[i]))||extension}"
        (click)="chooseButton(i)"
      >{{number}}</button>
    </div>
    <p>
      <b>Points: {{points}}</b>
    </p>
  </div>

  <button mat-raised-button color="primary"
    *ngIf="newButtonsChosen.size != 0 && phase == 2"
    (click)="keepNumbers()"
    style="margin-right: 15px;">
  Keep</button>

  <button mat-raised-button color="primary"
    *ngIf="phase == 3 || (phase == 2 && points>=350)"
    (click)="endRound()">
  End Round</button>

  <button mat-raised-button color="primary"
    *ngIf="phase == 4"
    (click)="extendRound()">
  Play Extension</button>

</mat-card>
