<mat-card>
  <mat-card-title>Points</mat-card-title>

  <table>
    <tr *ngFor="let key of keys()">
      <td class="table-item">Round {{key}}</td>
      <td class="table-item">+ {{pointAccount[key]}}</td>
    </tr>
    <tr>
      <td class="table-footer-item">Total</td>
      <td class="table-footer-item">{{getTotalPoints()}}</td>
    </tr>
  </table>
<!--
  <div *ngIf="getTotalPoints() >= 0">
    <h1>Congratulations!</h1>
    <h2>You've reached 10,000 points and win the game!</h2>
  </div> -->

</mat-card>
