<mat-toolbar color="primary" style="position:fixed; top: 0px; left: 0px;">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.drawer.toggle()" color="white">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span style="margin-left: 5px;">Insa's Website</span>

  <span class="example-spacer"></span>

  <a mat-icon-button target="_blank" href="https://github.com/insabelter">
    <img src="assets/header-icons/github-image.svg" height="24px" alt="github icon">
  </a>
  <a mat-icon-button target="_blank" href="https://www.linkedin.com/in/insa-belter-5092b51b6/">
    <img src="assets/header-icons/linkedin.svg" height="24px" alt="linkedin icon">
  </a>
  <a mat-icon-button target="_blank" href="https://www.xing.com/profile/Insa_Belter">
    <img src="assets/header-icons/xing.svg" height="24px" alt="xing icon">
  </a>
  <a mat-icon-button target="_blank" href="https://www.instagram.com/insa_bel/">
    <img src="assets/header-icons/instagram.svg" height="24px" alt="instagram icon">
  </a>

</mat-toolbar>
