import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ProjectsPageComponent } from './components/projects-page/projects-page.component';
import { GamesPageComponent } from './components/games-page/games-page.component';


const routes: Routes = [
  {path: '', redirectTo: 'about-me', pathMatch:'full'},
  {path: 'about-me', component:HomePageComponent},
  {path: 'projects', component:ProjectsPageComponent},
  {path: 'games', component:GamesPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
