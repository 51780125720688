import { Component, Input } from '@angular/core';
import { DicesApiService } from '../../../services/dices-api.service';
import { MatDialog } from '@angular/material/dialog';
import { WinDialogComponent } from '../play-dice-window/win-dialog/win-dialog.component';

@Component({
  selector: 'app-play-dice-window',
  templateUrl: './play-dice-window.component.html',
  styleUrls: ['./play-dice-window.component.scss']
})
export class PlayDiceWindowComponent {
  @Input() pointAccount: Record<number, number>;
  totalPoints = 0;

  // Phase 0: Start of new round
              // Dice: shown
              // Results: hidden

  // Phase 1: After last try finished, before next try
              // Dice: shown
              // Results: shown from last round (greyed?) (kept ones marked)

  // 0 --> 2: Dicing
  // 1 --> 2: Dicing

  // Phase 2: Results from dicing are shown, you should keep dices!
              // Dice: hidden/greyed
              // Results: some clickable (kept ones marked)
              // + Keep Button if button clicked

  // 2 --> 3: If no buttons can be chosen or third try
  // 2 --> 1: Keep Button clicked

  // Phase 3: End Round shown
              // Dice: hidden
              // Results: not clickable, that that bring points marked

  // 3 --> 0: End Round clicked

  // Phase 4: Extension needed
            // Dice: hidden
            // Results: not clickable
            // + Extend Button

  // 4 --> 0: Extend Button clicked

  phase = 0;
  round = 1;
  try = 1;

  diceResults: number[] = [0,0,0,0,0];
  points: number = 0;
  ePoints: number = 0;
  extension: boolean = false;
  extensionTitle: boolean = false;
  partOfPasch: Set<number> = new Set();

  buttonStates = [];
  buttonsChosen: Set<number> = new Set();
  newButtonsChosen: Set<number> = new Set();

  constructor(private dicesApiService: DicesApiService, private dialog: MatDialog) { }

  // MAIN FUNCTION: ROLL DICES --------------------------------------------------

  async onRollTheDices(){

    // 0 --> 2, 1 --> 2
    this.phase = 2;

    this.buttonStates = [false, false, false, false, false];

    // API Request and variable initialization
    await this.dicingRequest();

    if(this.extension){
      this.phase = 4;
    }
    else{
      // Hide buttons which don't gain points
      var allHidden = this.hideButtons();

      // Decide if round ends here --> phase 3
      if(allHidden){
        this.points = 0;
        this.phase = 3;
      }
      if(this.try==3){
        // 2 --> 3
        this.phase = 3;
      }
    }

  }

  private async dicingRequest(){
    var temp = await this.dicesApiService.rollDices(this.getResultSchema());
    this.diceResults = temp[0];
    this.points = temp[1] + this.ePoints;
    this.extension = temp[2];
    var partOfPaschArray = temp[3];
    this.partOfPasch = new Set();
    for(var i of partOfPaschArray){
      this.partOfPasch.add(i);
    }
  }

  private getResultSchema(){
    var resultSchema: number[] = [0,0,0,0,0];

    for(var i of this.buttonsChosen){
      resultSchema[i] = this.diceResults[i];
    }

    return resultSchema;
  }

  private hideButtons(){

    this.buttonStates = [true, true, true, true, true];

    var allHidden = true;
    for (let i = 0; i < 5; i++) {
      if(this.diceResults[i] == 1 || this.diceResults[i] == 5 || this.partOfPasch.has(i)){
        this.buttonStates[i] = false;
        if(!(this.buttonsChosen.has(i) && !this.newButtonsChosen.has(i))){
          allHidden = false;
        }

      }
    }

    return allHidden;
  }

  private async openDialog() {
    const dialogRef = this.dialog.open(WinDialogComponent);
    dialogRef.disableClose = true;

    var result = await dialogRef.afterClosed().toPromise();
    // console.log(`Dialog result: ${result}`);
    // if (result) {
    //   this.restartGame();
    // }
  }

  private restartGame(){
    this.phase = 0;
    this.round = 1;
    this.try = 1;

    this.diceResults = [0,0,0,0,0];
    this.points = 0;
    this.ePoints = 0;
    this.extension = false;
    this.partOfPasch = new Set();

    this.buttonStates = [];
    this.buttonsChosen = new Set();
    this.newButtonsChosen = new Set();

    // console.log(this.pointAccount);
    this.pointAccount = {};
    // console.log(this.pointAccount);
    this.totalPoints = 0;
  }


  // FUNCTIONS FROM OTHER BUTTONS ---------------------------------------------------------------------

  keepNumbers(){
    this.newButtonsChosen = new Set();
    this.try ++;

    // 2 --> 1
    this.phase = 1;
  }

  extendRound(){
    this.ePoints = this.points;

    this.buttonsChosen = new Set();
    this.newButtonsChosen = new Set();
    this.diceResults = [0,0,0,0,0];
    this.try = 1;
    this.extensionTitle = true;

    this.phase = 0;
  }

  // End Round Button
  endRound(){
    if(this.points >= 350){
      this.pointAccount[this.round] = this.points;
      // console.log(this.pointAccount);
      this.totalPoints += this.points;
    }

    if(this.totalPoints >= 10000){
      this.openDialog();
    }

    this.round++;
    this.try = 1;
    this.buttonsChosen = new Set();
    this.newButtonsChosen = new Set();
    this.diceResults = [0,0,0,0,0];

    this.extension = false;
    this.extensionTitle = false;
    this.ePoints = 0;

    // 3 --> 0
    this.phase = 0;
  }

  // Number Buttons
  chooseButton(index: number){
    // Pasch
    if(this.partOfPasch.has(index)){
      if(this.newButtonsChosen.has(index)){
        for(var i of this.partOfPasch){
          this.newButtonsChosen.delete(i);
          this.buttonsChosen.delete(i);
        }
      }
      else{
        for(var i of this.partOfPasch){
          this.newButtonsChosen.add(i);
          this.buttonsChosen.add(i);
        }
      }
    }
    // Not Pasch
    else{
      if(this.newButtonsChosen.has(index)){
        this.newButtonsChosen.delete(index);
        this.buttonsChosen.delete(index);
      }
      else{
        this.newButtonsChosen.add(index);
        this.buttonsChosen.add(index);
      }
    }
  }

}
