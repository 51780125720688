<mat-sidenav-container autosize class="example-container">
  <mat-sidenav #drawer mode="over" role="navigation">
    <mat-nav-list>
      <a mat-list-item routerLink='about-me' [routerLinkActive]="['active']">About Me</a>
      <a mat-list-item routerLink='projects' [routerLinkActive]="['active']">Projects</a>
      <a mat-list-item routerLink='games' [routerLinkActive]="['active']">Games</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <main style="width: 80%; margin: 1em auto;">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

