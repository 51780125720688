<h1>My Projects</h1>

<mat-card>
  <mat-card-title><img src="assets/project-icons/covid-jobber.png" alt="Covid Jobber Icon">Covid Jobber</mat-card-title>

  This university project was about developing a mobile application in a few months in a group of three students.<br>
  With the start of the COVID-19 pandemic, our goal was to create an application which could help in the job finding process.<br>
  <br>
  The finished android application can be downloaded here:<br><br>

  <button mat-raised-button color="primary">
    <a href="https://drive.google.com/file/d/1HXtEDfST1eBCukraW2QluDcuiQPQ2xyR/view?usp=sharing" target="_blank">Download Covid Jobber</a>
  </button><br>

  <br>
  <h4 style="margin-bottom: 8px;">Installation guide:</h4>
  1. Download the "covid-jobber.apk" file<br>
  2. Navigate to your "Downloads" folder and find the "covid-jobber.apk" file<br>
  3. Install the app directly from there by clicking the "covid-jobber.apk" file<br>
</mat-card>

<mat-card>
  <mat-card-title><img src="assets/project-icons/dystopia-defence.png" alt="Covid Jobber Icon">Dystopia Defence</mat-card-title>

  This university project was part of the module "Software Engineering".<br>
  The goal was to develop a tower defence game in just a few months in a team of 12 students.<br>
  My tasks were mainly to draw and animate the different enemies, some of the towers and the background setting.<br>
 <br>
  The project was a lot of fun, so I would really appreciate it if you gave the game a try:<br><br>

  <button mat-raised-button color="primary">
    <a href="https://teamkenny.gitlab.io/towerdefence/" target="_blank">Test Dystopia Defence</a>
  </button>
</mat-card>

<mat-card>
  <mat-card-title><img src="assets/project-icons/pokecards.png" alt="Covid Jobber Icon">PokéCards</mat-card-title>

  This university project challenged me and two fellow students to develop a website in just a few weeks.<br>
  We could choose any topic we wanted, so we decided on a marketplace for Pokémon Cards as we all love to play the different Pokémon games.<br>
  <br>
  If you are interested in Pokémon as well or otherwise interested in the finished website,<br>
  you are invited to visit our website by clicking the button below:<br><br>

  <button mat-raised-button color="primary">
    <a href="https://pokecards.site/" target="_blank">Visit PokéCards</a>
  </button>

</mat-card>
