import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, FormControlDirective, NgForm } from '@angular/forms';
import { timeout } from 'rxjs/operators';
import { MailService } from 'src/app/services/mail.service';
import { VarificationService } from 'src/app/services/varification.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  @ViewChild('form', {static: true }) form: NgForm;

  varificated: boolean = false;
  messageForm: FormGroup;
  notification: string ='';
  constructor(private MailService: MailService, private VarificationService: VarificationService) { }

  ngOnInit(): void {
    this.addRecaptchaScript();
    this.notification = "";
    this.messageForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'message': new FormControl('', Validators.required)
    })
  }

  async onSendMessage(){

    var name = this.messageForm.controls.name.value;
    var mailAddress = this.messageForm.controls.email.value;
    var message = this.messageForm.controls.message.value;

    if(this.messageForm.valid){
      if(this.varificated){
        try{
          await this.MailService.sendMail(name, mailAddress, message);
          this.notification = "Successfully sent the message!";
          this.messageForm.reset();
          setTimeout(() => {
            this.notification = "";
          }, 2000);
        }
        catch(err){
          this.notification = "Server error: The message could not be sent!";
        }
      }
      else{
        this.notification = "Please finish the varification first!";
      }
    }
    else {
      this.notification = "Please fill every field first!";
    }

  }

  // reCAPTCHA
  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6Ldl-8wZAAAAAPFPS6i4pz3SBYhVaCJGQv2vAoiq',
      'callback': (response) => {
          console.log(response);
          try{
            this.VarificationService.varificate(response);
            this.varificated = true;
          }catch(err){
            console.log(err);
          }
      }
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

}
