<h1>Dice 10,000</h1>

<mat-accordion>
  <mat-expansion-panel style="margin-bottom: 15px;"
                       (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Rules
      </mat-panel-title>
      <!-- <mat-panel-description>
        Currently I am {{panelOpenState ? 'open' : 'closed'}}
      </mat-panel-description> -->
    </mat-expansion-panel-header>
      Dice 10,000 is a dice game which exists with several different names and different rule spezifications.<br>
      These are the rules I learned through playing with my grandparents:<br><br>

      <h4>General Gameplay</h4>
      The players take turns and roll five dices to gain points.<br>
      <br>
      With every turn, the player rolls the dices three times.<br>
      Every try, at least one dice needs to gain points and is layed aside.<br>
      Not all dices that gain points need to be layed aside though.<br>
      In some cases it could be clever to roll them again and hope for a better score.<br>
      If none of the dices gain points, the turn is ended earlier.<br>
      If the player has at least 350 points before their last try, they can decide to end the turn earlier and keep the received points.<br>
      <br>
      The achieved points from the turns are added to the players points accounts.<br>
      The first player to reach 10000 points wins the game!<br><br>

      <h4>Score Calculation</h4>
      <ul>
        <li>Singular Ones are 100 points, singular Fives are 50 points</li>
        <li>Three dices of the same number count 100 times this number (Three Sixes: 600 points)<br><b>Exception: </b>Three Ones are 1000 points</li>
        <li>Four dices of the same number count 200 times this number, five dices count 400 times this number.<br><b>Exception: </b>Four Ones are 2000 points, Five Ones are 4000 points</li>
        <li>Only scores from 350 points are added to the points account of the player</li>
      </ul>

      <h4>Extension Turns</h4>
      If all dices of a try gain points, the turn is not ended, but instead an extension turn is started.<br>
      In this extension turn, the dices are rolled again for a maximum of three times.<br>
      The gained points from this extension turn are added to the points of the previous turn.<br>
      Again, every try one dice at least needs to be layed aside.<br>
      When at least three dices gain points are achieved, the turn can be ended and the total points are added to the player's points account.<br>
      If otherwise the player cannot achieve three dices that gain new points in the extension turn, all points are lost and the turn is ended.<br>
      An extension turn can also again be continued with another extension turn.<br>
    </mat-expansion-panel>
</mat-accordion>

<div class="container">
  <div>
    <!-- Window to play -->
    <app-play-dice-window [pointAccount]="pointAccount"></app-play-dice-window>
  </div>
  <div>
    <!-- Window for points -->
    <app-dice-points-window [pointAccount]="pointAccount"></app-dice-points-window>
  </div>
</div>

