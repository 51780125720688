import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dice-points-window',
  templateUrl: './dice-points-window.component.html',
  styleUrls: ['./dice-points-window.component.scss']
})
export class DicePointsWindowComponent {

  @Input() pointAccount: Record<number, number> = {};

  keys(){
    return Object.keys(this.pointAccount);
  }

  getTotalPoints() {
    var sum = 0;
    for(let key of this.keys()){
      sum += this.pointAccount[key];
    }

    // console.log(sum);
    return sum;
  }

}
