import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VarificationService {

  constructor(private http: HttpClient){}

  apiUrl: string = 'https://insabelter.de/server/varification';
  testUrl: string = 'http://localhost:3000/api/varification';

  async varificate(responseKey: string){
    let toServer = Object.assign({response: responseKey});

    // server/varification
    const response = await this.http.post(this.apiUrl, toServer)
    .pipe(catchError((err)=>{
      return throwError(err.error);
    })).toPromise();

    console.log(response);
  }

}
