import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DicesApiService {

  apiUrl: string = 'https://insabelter.de/server/dices';
  testUrl: string = 'http://localhost:3000/api/dices'

  constructor(private http: HttpClient) { }

  async rollDices(resultSchema: number[]){

    let toServer = Object.assign({resultSchema: resultSchema});

    const response: any = await this.http.post(this.apiUrl, toServer)
    .pipe(catchError((err)=>{
      return throwError(err.error);
    })).toPromise();

    // console.log(response);

    return [response.results, response.points, response.extension, response.partOfPasch];
  }

}
