import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient){}

  apiUrl: string = 'https://insabelter.de/server/mail';
  testUrl: string = 'http://localhost:3000/api/mail';

  async sendMail(name: string, mailAddress: string, message: string){
    let toServer = Object.assign({name: name, mailAddress: mailAddress, message: message});
    console.log("Sending Mail with name "+name+", mail "+mailAddress+", message "+message);

    const response = await this.http.post(this.apiUrl, toServer)
    .pipe(catchError((err)=>{
      return throwError(err.error);
    })).toPromise();
  }

}
